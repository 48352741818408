.home {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .homeLogo {
    max-width: 250px;
  }

  .pageName {
    font-size: 1.8rem;
    max-width: 200px;
    text-align: center;
    font-weight: 300;
  }

  .details {
    font-size: 0.8rem;
    display: flex;
    flex-direction: row;
    justify-content: center;

    a {
      padding: 1em;
    }
  }
}

@media screen and (min-width: 40em) {
  .homeLogo {
    max-width: 300px;
  }
}
