.contactForm {
  min-width: 50%;
  position: relative;
  text-align: center;
  max-width: 500px;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h2 {
    margin-top: 2rem;
  }

  input,
  textarea {
    margin: 0.5rem;
    padding: 0.4rem;
    width: 100%;
    border: 1px solid $navy;
    font-size: 0.8em;
  }

  textarea {
    height: 6rem;
  }

  input:focus {
    border: 1px solid $orange;
  }

  textarea:focus {
    border: 1px solid $orange;
  }

  input[type="submit"] {
    width: 100%;
    padding: 0.4rem;
    margin: 0.5rem;

    &:hover {
      background-color: $orange;
    }
  }
}