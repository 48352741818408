.navLogo {
  text-align: justify;
  display: flex;

  .title {
    display: flex;
    align-items: center;
    margin-left: -0.8em;

    .name {
      margin-left: 1em;
      margin-right: 2.5em;
      padding-top: 0.1em;
      border-right: 2px solid $navy;
    }

    .page {
      color: $orange;
      max-width: 6em;
      text-align: left;
    }

    p {
      margin: 0;
      font-size: 1.5rem;
    }
  }
}

@media screen and (max-width: 39em) {
  header {
    padding-top: 1.5em;
    padding-left: 4em;
    width: 100%;
    position: fixed;
    background-color: white;
    z-index: 900;

    .navLogo {
      .title {
        flex-direction: row;
        margin-left: -2.8em;
        

        p {
          font-size: 1.3rem;
          line-height: 1em;
        }
        .name {
          padding-right: 0.5em;
          margin: 0.5em;
          max-width: 6em;
          text-align: right;
        }

        .tag {
          display: none;
        }
      }
    }

    .links {
      display: none;
    }
  }
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0;

  li {
    list-style-type: none;
    padding: 0 0.5em;
    border-radius: 30%;
    margin: 0 0.5em;
    padding: 0.5em;

    .selected {
      color: $orange;
    }
  }
}

@media screen and (min-width: 40em) {
  nav {
    width: 100%;
  }

  .page {
    display: none;
  }

  .links {
    margin-top: 0;
    margin-bottom: 0;
  }
}
