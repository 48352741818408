.pricing,
.calendar {
  text-align: center;
}

.calendar {
  display: flex;
  flex-direction: column;

  div {
    margin: 0 2rem;
  }
}

