@import "parameters";
@import "menu";
@import "header";
@import "pages/home";
@import "pages/pricing";
@import "pages/contact";

* {
  font-family: $poppins;
  text-decoration: none;
}

body,
html {
  margin: 0;
  padding: 0;
  text-align: center;
  color: $navy;
  background-color: #fff;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.App {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;;
}

a {
  color: $navy;
}

.text {
  text-align: justify;
  margin: 1rem;
  padding-top: 7em;
  padding-bottom: 9.5em;
}

.gallery {
  padding-top: 7em;
  padding-bottom: 10.2em;
}

.galleryContent {
  max-height: 0;
  color: white;
  font-size: 1px;
}

.pageTitle {
  display: none;
}

footer {
  display: flex;
  flex-direction: column;
  background-color: $navy;
  color: white;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: center;

  p {
    margin-bottom: 0;
  }

  a {
    color: white;
    padding-left: 1em;

    &:first-of-type {
      border-right: 1px solid white;
      padding-right: 1em;
    }
  }
    .insta {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 1em;
    }
}

.covid {
  background-color: $navy;
  color: white;
  padding: 0;
  margin-top: 7em;
  margin-bottom: -7em;
  font-size: 1.2em;
  width: 100%;

  p {
    padding: 0 1em;
  }
  a {
    color: white;
  }
}

@media screen and (min-width: 40em) {
  .text {
    margin: 0 8rem;
    padding-top: 1em;
  }

  .text,
  .gallery {
    padding-bottom: 9em;
  }

  .gallery {
    padding-top: 0;
  }

  header {
    align-self: flex-start;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1000;
  }

  footer {
    height: 9em;


  .insta {
      flex-direction: row;
    }
  }

  .covid {
    margin-top: 0;
    margin-bottom: 0;
  }
}
