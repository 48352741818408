.menu-btn {
  font-size: 100%;
  vertical-align: top;
  left: 0;
  background-color: rgba(252, 249, 249, 0.781);
  border: none;
  border-radius: 0 0 10px 0;
  position: fixed;
  color: $navy;
  padding: 0.5em 1em;
  z-index: 999;
}

.overlay {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 999;

  .links {
    display: flex;
    flex-direction: column;
    padding-top: 3em;

    li {
      padding: 1em;
    }
  }

  .selected {
    color: $orange;
  }

  .logo {
    position: absolute;
    top: 2em;
  }

  .close {
    color: $navy;
    padding: 10px;
    position: absolute;
    top: 0.3rem;
    right: 0.5rem;
  }
}

@media screen and (min-width: 40em) {
  .menu-btn {
    display: none;
  }

  .menu {
    display: none;
  }
}
